import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useAccount } from 'wagmi';
import {
    getTokenContract,
    getEtherSigner,
    getTokenContract_Atrium,
    toBigNum,
    fromBigNum
} from '../../services/web3.service';

import LogIn from "../../pages/LogIn/LogIn";
import Media from "../../pages/Media/Media";
import { Navigation } from "../../components/Navigation/Navigation";



const Token = () => {

    const [currentPage, setCurrentPage] = useState(<LogIn />);

    const [hasNFT, setHasNFT] = useState(false);
    const [balance , setBalance] = useState(0);

    const {address, isConnected, status} = useAccount();


     useEffect(()=>{
        const check = async () => {
             if (isConnected) {
                 if (!hasNFT || currentPage !== <Media/>) {
                     await getBalance();
                     if (hasNFT) {
                         setCurrentPage(<Media/>)
                     } else {
                         setCurrentPage(<LogIn status={isConnected} nft={hasNFT}/>)
                     }
                 }
             } else {
                 setHasNFT(false);
                 setCurrentPage(<LogIn status={isConnected} nft={hasNFT}/>)
             }
         }
         check();
    }, [isConnected, balance, status, hasNFT]);

    const getBalance = async () => {

        const signer = await getEtherSigner();
        if(signer !== null) {
            const tokenContract = await getTokenContract(signer);
            // const tokenContract_Atr = await getTokenContract_Atrium(signer);
        
        
            //console.log("Balances:");
            // let balance_Moon = await tokenContract.balanceOf(address);
            // console.log("Moonizens Balance: " + balance_Moon);
            // let balance_Atrium = await tokenContract_Atr.balanceOf(address);
            // console.log("StarWave Balance: " + balance_Atrium);
            // let balance_ = balance_Moon.add(balance_Atrium);
            let balance_ = fromBigNum(toBigNum(await tokenContract.balanceOf(address)));
            //console.log("Combined Balance: " + balance_);
            let result = balance_ > 0;
            if(hasNFT !== result) {
                setHasNFT(result);
            }
        }
       // return balance_.gt(0);
    }
    

    return(
        <Box>
            <Navigation />
            {currentPage}
        </Box>
    );
};

export default Token;