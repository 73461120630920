import React from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { Carousel } from '../../components/Carousel/Carousel';
import { Logo } from '../../components/Logo/Logo';

import styles from './Mint.module.css';
import 'animate.css';
import './Media.css';

import genesisBanner from "../../assets/Token Gated Content/Genesis banner.png";
import atriazenBanner from "../../assets/Atriazen Artifact/Atriazen Banner.png";
import genesisDesktop from "../../assets/Token Gated Content/Genesis Desktop Wallpaper.png";
import atriazenDesktop from "../../assets/Atriazen Artifact/Atriazen Desktop.png";
import star from "../../assets/Media Page UI-UX/card-genesis.0f394c8f162266b70c60.png";

{/*TODO: Fix download links on wallpaper files
         Add chain and address buttons*/}

const Media = () =>{

    return(
        <div className={styles.pageWrapper}>
            <img src={star} alt="star" className={styles.backgroundStar} />
            <div className={styles.contentWrapper}>
                <center><Logo /></center>
                

                {/* Display of Mobile Wallpapers */}
                <div className="WallPaper_consoleWrapper__s35Za">
                    <Box className="WallPaper_content__qQj6-">
                        <div className="slick-slider slick-initialized" dir="ltr">

                            
                            
                            <div className="slick-list" style={{ padding: '0px'}}>
                                <div className="slick-track" style={{ width: '90%', opacity: 1, transform: 'translate3d(0px, 0px, 0px)' }}>
                                    <Carousel />

                                </div>

                            </div>
                        </div>
                     </Box>
                </div>

                {/*Display the Genesis Banner*/}

                <Box className="Media_bannerContainer__T1CtH">
                    <img src={genesisBanner} style={{height: "fit-content-length(90%)"}} alt=''/>
                </Box>
                <Stack style={{display:"flex",
                flexDirection:'row',
                WebkitBoxPack:"center",
                justifyContent:"center",
                WebkitBoxAlign: "center",
                alignItems:"center"}}>
                    <a download="Genesis Banner.png" href={`${genesisBanner}`} className="Media_downloadLink__tQMxS">Genesis Banner</a>
                </Stack>

                <Box className="Media_bannerContainer__T1CtH">
                    <img src={atriazenBanner} style={{height: "fit-content-length(90%)"}} alt=''/>
                </Box>
                <Stack style={{display:"flex",
                flexDirection:'row',
                WebkitBoxPack:"center",
                justifyContent:"center",
                WebkitBoxAlign: "center",
                alignItems:"center"}}>
                    <a download="Atriazen Banner.png" href={`${atriazenBanner}`} className="Media_downloadLink__tQMxS">Atriazen Banner</a>
                </Stack>

                {/*Display the Desktop Wallpaper*/}

                <Box className="WallPaper_container">
                    <img className="WallPaper_content__qQj6-" src={genesisDesktop} style={{height: "fit-content-length(90%)"}} alt=''/>
                </Box>
                <Stack style={{display:"flex",
                flexDirection:'row',
                WebkitBoxPack:"center",
                justifyContent:"center",
                WebkitBoxAlign: "center",
                alignItems:"center"}}>
                    <a download="Genesis Desktop Wallpaper.png" href={`${genesisDesktop}`} className="Media_downloadLink__tQMxS">Genesis Desktop</a>
                </Stack>

                <Box className="WallPaper_container">
                    <img className="WallPaper_content__qQj6-"src={atriazenDesktop} style={{height: "fit-content-length(90%)"}} alt=''/>
                </Box>
                <Stack style={{display:"flex",
                flexDirection:'row',
                WebkitBoxPack:"center",
                justifyContent:"center",
                WebkitBoxAlign: "center",
                alignItems:"center"}}>
                    <a download="Atriazen Desktop Wallpaper.png" href={`${atriazenDesktop}`} className="Media_downloadLink__tQMxS">Atriazen Desktop</a>
                </Stack>

            </div>
        </div>
    );
}

export default Media;
