import React from 'react';
import { Box } from '@mui/material';
import { MessageConsole } from '../../components/MessageConsole/MessageConsole';

import 'animate.css';
import styles from "./Mint.module.css";

import star from "../../assets/Media Page UI-UX/card-genesis.0f394c8f162266b70c60.png";
import logo from "../../assets/images/Media-page-landing-logo.svg";


const LogIn = ({status = false, nft = false}) => {

    var text = '';

    const setText = (status, nft) => {
        if(!status && !nft){
            text = 'Connect Your Wallet!';
            return(text);
        }
        else if(status && !nft){
            text = 'You Must Have An NFT To Access!';
            return(text);
        }
        else if(!status && nft){
            text= 'Error: Please Try Again!';
            return(text);
        }
        else{
            text = 'You May Pass';
            return(text);
        }
}
    

    return(
        <Box className={styles.pageWrapper}>
            <Box className={styles.bgImage}></Box>
            <Box className={styles.contentWrapper}>                    
                <img src={star} alt="star" className={styles.backgroundStar} />

                <img className={styles.logoImage} src={logo} loading='lazy' alt = 'Moonizen Logo' width="500px" height="200px"/>
                <MessageConsole text={setText(status, nft)}/>
            </Box>
        </Box>
    );
};

export default LogIn;