import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Web3ReactProvider } from '@web3-react/core';
import getLibrary from './config/getLibrary';

import useCustomTheme from './hooks/useCustomTheme';    
import Token from './pages/Token_Gating/Token_Gating';
import './App.css';

function App() {
    const { customTheme } = useCustomTheme();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={customTheme}>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <BrowserRouter>
                            <Routes>
                                <Route exact path='/' element={<Token />} />
                            </Routes>
                    </BrowserRouter>
                </Web3ReactProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
