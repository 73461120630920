import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/material';

import apex from "../../assets/Token Gated Content/Apex Mobile Wallpaper.png";
import andros from "../../assets/Token Gated Content/Andros Mobile Wallpaper.png";
import genesis from "../../assets/Token Gated Content/Genesis Mobile Wallpaper.png";
import guerrilla from "../../assets/Token Gated Content/Guerrilla Mobile Wallpaper.png";
import opifex from "../../assets/Token Gated Content/Opifex Mobile Wallpaper.png";
import selene from "../../assets/Token Gated Content/Selene Mobile Wallpaper.png";
import atriazen from "../../assets/Atriazen Artifact/Atriazen Mobile Wallpaper.png";



const Carousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        centerMode: true,
        centerPadding: '60px',
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        lazyLoad: true,
        adaptiveHeight: true,
        // variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '60px',
                    arrows: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '60px',
                    arrows: true,
                    
                }
            }
        ]
    }

    const images = [
        {image:atriazen, name:"Atriazen"},
        {image:genesis, name:"Genesis"},
        {image:apex, name:"Apex"},
        {image:andros, name:"Andros"},
        {image:guerrilla, name:"Guerrilla"},
        {image:opifex, name:"Opifex"},
        {image:selene, name:"Selene"},
    ]

    return(

            <Slider
              {...settings}
            >
              {images.map((item, index) => (

                <Box px={1} key={index}>
                  <Box className="itemContainer">
                    <img src={item.image} alt="Wallpaper" className="slickItemImage"/>
                  </Box>
               {/*  </Box> */}

                <Box>
                    <a download={`${item.name}_Mobile_Wallpaper.png`} href={`${item.image}`} style={{display:"flex", flexDirection:"row",WebkitBoxPack:"center",justifyContent:'center',WebkitBoxAlign:'center',alignItems:'center'}} className="Media_downloadLink__tQMxS">{item.name} Mobile Wallpaper </a>
                </Box>
              </Box>
              ))}
          </Slider>
    );
};

export{Carousel};
