import React from 'react';
import styles  from './MessageConsole.module.css';
import Star from '../../assets/images/Star-small.png';

const MessageConsole = ({ text, isFirstConsole }) => {

    return (
        <div className={styles.consoleWrapper}>
            <img src={Star} alt="star" className={styles.star} />
            <p className={styles.textWrapper}>{text}</p>
        </div>
    );
};

export{MessageConsole};
