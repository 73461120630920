import React from 'react';

import './Logo.css';

import genesisLogo from '../../assets/images/Media-page-connected-logo.svg';

const Logo = ({ size = 'large' }) => {

  return (
      <img className={`logo ${size}`} src={genesisLogo} loading='lazy' alt=''
      />
  );
};

export{Logo};
